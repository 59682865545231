<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 23 22"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g :fill="color">
        <g transform="translate(-361 -39) translate(361 39)">
          <rect
            width="27"
            height="4"
            x="-2"
            y="9"
            rx="2"
            transform="rotate(-44 11.5 11)"
          />
          <rect
            width="27"
            height="4"
            x="-2"
            y="9"
            rx="2"
            transform="scale(-1 1) rotate(-44 0 39.463)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 23
    },
    variant: {
      type: String,
      default: 'light' // light, neutral, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'neutral') {
        return '#c7c7c7'
      }
      if (props.variant === 'light') {
        return '#fafafa'
      }

      // Default: light
      return '#fafafa'
    })()

    const width = props.size
    const height = props.size * (23 / 22)
    
    return {
      width,
      height,
      color,
    }
  }
})
</script>
