// Detecta solo los cambios de ancho de la pantalla e ignora los de altura.
export function useOnScreenWidthResize(func: () => void) {
  const screenWidth = ref(0)
  const callbackFunc = ref<(() => void)>(func)

  function onScreenResize() {
    if (screenWidth.value !== screen.width && callbackFunc.value) {
      callbackFunc.value()
    }

    screenWidth.value = screen.width
  }

  function addResizeListener(func: () => void) {
    callbackFunc.value = func
  }

  onMounted(() => {
    screenWidth.value = screen.width
    window.addEventListener('resize', onScreenResize, true)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onScreenResize, true)
  })

  return {
    addResizeListener,
  }
}
